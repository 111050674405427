<template>
  <v-row>
    <v-col md="12">
      <v-card>
        <v-card-title>
         <!-- <v-icon
            class="mr-4"
            style="cursor: pointer"
            title="voltar"
            @click="pushRouteToView('bs')"
          >
            mdi-arrow-left
          </v-icon>-->
          <v-btn small  @click="pushRouteToView('bs')" class="mr-2 btn-back" >
                            <v-icon >
                        mdi-arrow-left
                             </v-icon>
                              Voltar
                            </v-btn>
          <v-icon class="mr-0">mdi-hair-dryer-outline</v-icon>
          Dados do Beauty Spot
            <v-spacer></v-spacer>
<!--          <v-alert :type="beautySpot.activeAPP ? 'success' : 'error'" dense text class="ma-0 py-1" >-->
<!--            {{ beautySpot.activeAPP ? 'Ativo' : 'Inativo' }} no Aplicativo-->
<!--          </v-alert>-->
          <span :class="(beautySpot.activeAPP ? 'btn-new' : 'btn-disabled') + ' mx-2 pa-1 px-2 subtitle-1 rounded'" >
            <v-icon left>{{beautySpot.activeAPP ? 'mdi-cellphone' : 'mdi-cellphone-off'}} </v-icon>  {{ beautySpot.activeAPP ? 'Ativo' : 'Inativo' }} no Aplicativo
          </span>

<!--          <v-btn outlined-->
<!--                 :class="`${beautySpot.activeAPP? 'btn-active': 'btn-inactive'} mx-2`">-->
<!--            <span v-if="beautySpot.activeAPP"><v-icon left>mdi-diameter-variant</v-icon>Inativar</span><span v-else><v-icon-->
<!--            left>mdi-checkbox-marked-circle-outline</v-icon>Ativar</span>-->
<!--          </v-btn>-->
          
          <v-btn outlined class="btn-del"
                 @click="dialogDeleteBeautySpot=true">
            <v-icon left>mdi-delete</v-icon>
            Excluir
          </v-btn>
          <v-btn outlined
                 :class="`${beautySpot.active? 'btn-inactive': 'btn-active'} mx-2`"
                 @click="dialogActiveInactive=true">
            <span v-if="beautySpot.active"><v-icon left>mdi-diameter-variant</v-icon>INATIVAR NO APLICATIVO</span>
            <span v-else><v-icon left>mdi-checkbox-marked-circle-outline</v-icon>ATIVAR NO APLICATIVO</span>
          </v-btn>
          <v-btn
            color="#3A33E8"
            dark
            :to="{ name: 'beautySpotEdit', params: beautySpot.id }"
          >
            <v-icon left>mdi-pencil</v-icon>
            Editar
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="text-data-visualization">
          <v-row>
            <v-col md="4" sm="12">
              <v-card flat>
                <v-img :src="bsLogo">
                  <v-card-title
                    class="align-end text-right fill-height"
                    primary-title
                  >
                    <v-spacer></v-spacer>
                  </v-card-title>
                </v-img>
              </v-card>
            </v-col>
          </v-row>


          <v-row>
            <v-col md="12" sm="12">
              <b><v-icon>mdi-card-account-details-outline</v-icon> CNPJ:</b>
              {{ beautySpot.cnpj }}
            </v-col>
          </v-row>
          <v-row>
            <v-col md="6" sm="6">
              <b><v-icon>mdi-office-building</v-icon> Nome da empresa:</b>
              {{ beautySpot.companyName }}
            </v-col>
            <v-col md="6" sm="6">
              <b><v-icon>mdi-office-building</v-icon> Nome fantasia:</b> {{ beautySpot.fantasyName }}
            </v-col>
          </v-row>
          <v-row>
            <v-col md="6" sm="6">
              <b><v-icon>mdi-at</v-icon> Email:</b>
              {{ beautySpot.email }}
            </v-col>
            <v-col md="6" sm="6">
              <b><v-icon>mdi-phone</v-icon> Telefone:</b> {{ beautySpot.phone }}
            </v-col>
          </v-row>

          <!--
          <v-row>
            <v-col md="6" sm="12"
            ><b>Descrição:</b>
              {{ beautySpot.desc }}
            </v-col>
          </v-row>
          -->
          <v-row>
            <v-col md="12" sm="12">
              <b><v-icon>mdi-map-marker</v-icon> CEP:</b>
              {{ beautySpot.zipcode }}
            </v-col>
          </v-row>
          <v-row>
              <v-col xl="9" lg="9" md="9" sm="12">
                <v-text-field
                  v-model="beautySpot.street"
                  label="Endereço"
                  :rules="textRules"
                  outlined
                  dense
                  append-icon="mdi-map-marker"
                  v-uppercase
                />
              </v-col>
              <v-col xl="3" lg="3" md="3" sm="12">
                <v-text-field
                  v-model.number="beautySpot.number"
                  type="number"
                  :rules="textRules"
                  label="Número"
                  outlined
                  dense
                  append-icon="mdi-map-marker"
                />
              </v-col>
            </v-row>
          <v-row>
            <v-col md="6" sm="12">
              <b><v-icon>mdi-map-marker</v-icon> Complemento</b>
              {{ beautySpot.addressComplement }}
            </v-col>
            <v-col md="6" sm="12">
              <b><v-icon>mdi-map-marker</v-icon> Bairro:</b>
              {{ beautySpot.district }}
            </v-col>
          </v-row>

          <v-row>

            <v-col md="6" sm="12">
              <b><v-icon>mdi-map-marker</v-icon> Cidade:</b>
              {{ beautySpot.city }}
            </v-col>
            <v-col md="6" sm="12">
              <b><v-icon>mdi-map-marker</v-icon> Estado:</b>
              {{ beautySpot.state }}
            </v-col>
          </v-row>
          <v-row>
            <v-col md="6" sm="12">
              <b><v-icon>mdi-form-select</v-icon> Categorias: </b>
              <span
                v-for="(category, index) in beautySpot.categories"
                :key="category.id"
                :rules="textRules"
              >
                {{ category.desc}}
                <span
                  v-if="index != Object.keys(beautySpot.categories).length - 1"
                >,
                </span>
              </span>
            </v-col>
            <v-col sm="6" v-show="beautySpot.lat">
              <b><v-icon>mdi-map-marker</v-icon> Localização: </b>
              <a
                :href="
                  'https://maps.google.com/?q=' +
                    beautySpot.lat +
                    ',' +
                    beautySpot.lon
                "
                target="_blank"
              >Visualizar no mapa</a
              >
            </v-col>
          </v-row>
          <v-row>

            <v-col md="6" sm="12">
              <b><v-icon>mdi-calendar</v-icon> Dia Vencimento:</b>
              {{ beautySpot.planDay }}
            </v-col>
            <v-col md="6" sm="12">
              <b><v-icon>mdi-currency-usd</v-icon> Valor Mensalidade:</b>
              {{ beautySpot.planValue | money}}
            </v-col>
            <v-col md="6" sm="12">
              <b><v-icon>mdi-calendar-cursor </v-icon> Dia de fechamento do saldo:</b>
              {{ beautySpot.balanceDay }}
            </v-col>
            <v-col md="6" sm="12">
              <b><v-icon>mdi-percent</v-icon> Taxa de Antecipação:</b>
              {{ beautySpot.rateAnticipation }} %
            </v-col>
            <v-col md="6" sm="12">
              <b><v-icon>mdi-percent</v-icon> Taxa de Antecipação do BM:</b>
              {{ beautySpot.rateAnticipationBm }} %
            </v-col>

          </v-row>
          <v-row>
          

          </v-row>

          <!--
          <v-row v-show="beautySpot.latitude">
            <v-col sm="12">
              <a
                :href="
                  'https://maps.google.com/?q=' +
                    beautySpot.latitude +
                    ',' +
                    beautySpot.longitude
                "
                target="_blank"
              >Localização</a
              >
            </v-col>
            <v-col md="12" sm="12"
            ><b>Endereço:</b><br/>
              {{ beautySpot.street }}, {{ beautySpot.number }}, &nbsp;
              {{ beautySpot.addressComplement }}
              {{ beautySpot.district }}
              <br/>
              {{ beautySpot.city }} - {{ beautySpot.state }}
            </v-col>
          </v-row>
          -->
          <v-row>
            <v-col sm="12" class="text-right">
              <v-btn small outlined>
                <v-icon>
                  mdi-lock-outline
                </v-icon>
                Trocar senha do master
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <confirm-dialog
          :dialog="dialogActiveInactive"
          :title="`${beautySpot.active? 'INATIVAR ': 'ATIVAR '} ${beautySpot.companyName}`"
          body="Tem certeza que deseja realizar essa ação?"
          @dialog-event="inactiveActive">
        </confirm-dialog>
        <confirm-dialog
          :dialog="dialogDeleteBeautySpot"
          :title="`EXCLUIR  ${beautySpot.companyName}`"
          :body="`Tem certeza que deseja excluir o serviço ${beautySpot.companyName} ?`"
          @dialog-event="deleteBeautySpot">
        </confirm-dialog>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import {mapGetters} from "vuex";
import store from "@/store";
import {
  BEAUTY_SPOT_DELETE,
  BEAUTY_SPOT_GET,
  BEAUTY_SPOT_LOGO_GET
} from "@/store/actions/beautyspots.type";
import {
  BEAUTY_SPOT_RESET,
  BEAUTY_SPOT_SET_LOGO, SET_BEAUTY_SPOT_ACTIVE
} from "@/store/mutations/beautyspots.type";
import {CHANGE_PROGRESS} from "@/store/mutations/mutations.type";
import {BeautySpotsService} from "@/common/api/beautyspot.service";
import ConfirmDialog from '@/components/ConfirmDialog'
import {BEAUTY_SPOT_ACTIVE, BEAUTY_SPOT_INACTIVE} from "@/store/actions/beautyspots.type";

export default {
  name: "beautyspot",

  props: {
    id: {
      default: 0,
      type: Number,
      required: true
    }
  },

  textRules: [
    v => !!v || "Este campo é obrigatório"
    //v => v > 0 || "Esta campo deve ter um valor maior que zero."
  ],
  components: {ConfirmDialog},
  async beforeRouteUpdate(to, from, next) {
    // Reset state if user goes from /editor/:id to /editor
    // The component is not recreated so we use to hook to reset the state.
    await store.commit(BEAUTY_SPOT_RESET);
    return next();
  },
  async beforeRouteEnter(to, from, next) {
    Promise.all([
      await store.commit(BEAUTY_SPOT_RESET),
      await store.dispatch(BEAUTY_SPOT_GET, to.params.id)
      //await store.dispatch(BEAUTY_SPOT_LOGO_GET, to.params.id)
    ]).then(() => {
      next();
    });
  },

  mounted() {
    if (this.beautySpot.id) {
      this.$store.dispatch(BEAUTY_SPOT_LOGO_GET, this.beautySpot.id);
    }
  },
  data: () => ({
    dialogActiveInactive: false,
    dialogDeleteBeautySpot: false
  }),

  methods: {
    pushRouteToView(route) {
      this.$router.push({name: route});
    },

    async previewImage(img) {
      if (img.target.files[0]) {
        store.commit(CHANGE_PROGRESS, true);
        await BeautySpotsService.createLogo(
          this.beautySpot.id,
          img.target.files[0]
        )
          .then(res => {
            console.log(res);
            store.commit(CHANGE_PROGRESS, false);

            this.snackbar.snackbar = true;
            this.snackbar.color = "success";
            this.snackbar.text = "Salvo com sucesso";
          })
          .catch(({response}) => {
            store.commit(CHANGE_PROGRESS, false);

            this.snackbar.snackbar = true;
            this.snackbar.color = "error";
            this.snackbar.text = "Erro ao salvar logo.";
            console.log(response);
            if (typeof response.data.message !== "undefined") {
              this.snackbar.text = `Erro ao salvar logo: ${response.data.message}`;
            }
          });
        store.commit(
          BEAUTY_SPOT_SET_LOGO,
          URL.createObjectURL(img.target.files[0])
        );
      }
    },
    deleteBeautySpot(status) {
      this.dialogDeleteBeautySpot = false
      if (!status) {
        return
      }
      this.$store.commit(CHANGE_PROGRESS, true)
      this.$store
        .dispatch(BEAUTY_SPOT_DELETE)
        .then(() => {

          this.$store.commit(CHANGE_PROGRESS, false)
          this.snackbar.snackbar = true;
          this.snackbar.color = "success";
          this.snackbar.text = "Excluído com sucesso";

          this.$router.push({
            name: "bs",
          });
        })
        .catch(({response}) => {
          this.$store.commit(CHANGE_PROGRESS, false)
          this.snackbar.snackbar = true;
          this.snackbar.color = "error";
          this.snackbar.text = "Erro ao excluir.";
          // console.log(response.data);
          if (typeof response.data.message !== "undefined") {
            this.snackbar.text = response.data.message;
          }
        });
    },
    inactiveActive(status) {
      this.dialogActiveInactive = false
      if (!status) {
        return
      }
      let action = this.beautySpot.active ? BEAUTY_SPOT_INACTIVE : BEAUTY_SPOT_ACTIVE;
      this.$store.commit(CHANGE_PROGRESS, true)

      this.$store
        .dispatch(action, this.beautySpot.id)
        .then(() => {

          this.$store.commit(CHANGE_PROGRESS, false)
          this.snackbar.snackbar = true;
          this.snackbar.color = "success";
          this.snackbar.text = "Alterado com sucesso";
          this.$store.commit(SET_BEAUTY_SPOT_ACTIVE, !this.beautySpot.active)
        })
        .catch(({response}) => {
          this.$store.commit(CHANGE_PROGRESS, false)
          this.snackbar.snackbar = true;
          this.snackbar.color = "error";
          this.snackbar.text = "Erro ao alterar.";
          // console.log(response.data);
          if (typeof response.data.message !== "undefined") {
            this.snackbar.text = response.data.message;
          }
        });
    },
  },
  computed: {
    ...mapGetters(["snackbar", "checkUsers", "beautySpot", "bsLogo"])
  }
};
</script>
